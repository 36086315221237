import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useAccount } from 'context/AccountProvider';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();

    let UserAcess = useSelector((e) => e?.customization?.userAccess)

    const uniqueScreens = [...new Set(UserAcess.map(obj => obj.screen))];

    const filteredObjects = item.children.filter(obj => uniqueScreens.includes(obj.id));

    console.log("UserAcess", UserAcess, uniqueScreens, filteredObjects);


    const { userDetails } = useAccount();
    let menuItemsChildren = [];
    if (userDetails.user_type !== 'internal') {
        console.log("allScreen", item.children);
        // const filterMenu = item.children.filter(
        //     (j) =>
        //         (j.id === 'select-plan' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
        //         j.id === 'my-subscriptions' ||
        //         // j.id === 'new-subscriptions' ||
        //         (j.id === 'my-organization' && userDetails.user_type === 'organisation') ||
        //         j.id === 'product-usage-time' ||
        //         (j.id === 'product_assign' && userDetails.user_type === 'organisation') ||
        //         (j.id === 'select-plan' && userDetails.user_type === 'individual') ||
        //         (j.id === 'purchase-plans') ||
        //         j.id === 'clients' ||
        //         j.id === 'matters' ||
        //         j.id === 'timesheet' ||
        //         j.id === 'new-timesheet' ||
        //         j.id === 'clauses' ||
        //         (j.id === 'role-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
        //         (j.id === 'designation-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
        //         (j.id === 'department-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
        //         (j.id === 'practice-area-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user'))
        // );

        const filterMenu = item.children.filter(
            (j) =>
                j.id === 'my-subscriptions' ||
                (j.id === 'my-organization' && userDetails.user_type === 'organisation') ||
                (j.id === 'organization-members' && userDetails.user_type === 'organisation') ||
                (j.id === 'my-invoice-template' && userDetails.user_type === 'organisation') ||
                j.id === 'product-usage-time' ||
                (j.id === 'product_assign' && userDetails.user_type === 'organisation') ||
                (j.id === 'select-plan' && userDetails.user_type === 'individual') ||
                (j.id === 'purchase-plans') ||
                j.id === 'clients' ||
                j.id === 'matters' ||
                j.id === 'timesheet' ||
                j.id === 'new-timesheet' ||
                j.id === 'clauses' ||
                j.id === 'my-access' ||
                j.id === 'my-data-access' ||
                (j.id === 'module-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
                (j.id === 'expense-masterdata' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
                (j.id === 'data-role-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
                (j.id === 'screen-role-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
                (j.id === 'designation-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
                (j.id === 'department-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
                (j.id === 'practice-area-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
                j.id === 'billing-invoice' || j.id === 'billing-expanse'
        );
        //userDetails.user_type === 'individual'
        menuItemsChildren = (userDetails.user_type === 'organisation' ||  userDetails.user_type === 'individual') ? [...filterMenu] : [...filteredObjects];
        // menuItemsChildren = [...filteredObjects];
    } else {
        const filterMenu = item.children.filter(
            (k) => k.id !== 'select-plan' && k.id !== 'purchase-plans' && k.id !== 'my-subscriptions'
                && k.id !== 'my-organization' && k.id !== 'product_assign' && k.id !== 'matters' && k.id !== 'clients' && k.id !== 'timesheet'
                && k.id !== 'new-timesheet' && k.id !== 'product-usage-time' && k.id !== 'clauses'
                && k.id !== 'screen-role-master'
                && k.id !== 'designation-master'
                && k.id !== 'department-master'
                && k.id !== 'practice-area-master'
                && k.id !== 'data-role-master'
                && k.id !== 'data-role-list'
                && k.id !== 'sa-setup'
                && k.id !== 'my-access'
                && k.id !== 'my-data-access'
                && k.id !== 'module-master'
                && k.id !== 'billing-invoice'
                && userDetails.user_type !== 'organisation'
        );
        menuItemsChildren = [...filterMenu];
    }

    // menu list collapse & items
    // const items = item.children?.map(
    //     (menu) =>
    // menu.role === 'organization' && <NavCollapse key={menu.id} menu={menu} level={1} /> && (
    //     <NavItem key={menu.id} item={menu} level={1} />
    // )
    // switch (menu.type) {
    //     case 'collapse':
    //         return menu.role === 'individual' && <NavCollapse key={menu.id} menu={menu} level={1} />;
    //     case 'item':
    //         return <NavItem key={menu.id} item={menu} level={1} />;
    //     default:
    //         return (
    //             <Typography key={menu.id} variant="h6" color="error" align="center">
    //                 Menu Items Error
    //             </Typography>
    //         );
    // }
    // );


    // menu list collapse & items
    const items = menuItemsChildren?.map((menu) => {
        console.log(menu, 'itemMenu');

        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {/* {item.title} */}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
