/* eslint-disable react/no-unknown-property */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU, USER_ACCESS, USER_DATA } from 'store/actions';
// assets
import { IconChevronRight } from '@tabler/icons';
import { useAccount } from 'context/AccountProvider';
import { getMyUserDetails } from 'services/UserService';
import { getUserAccessScreenWise } from 'services/UserAccessMasterService';
import { createSubscription } from 'services/ZohoSubscriptions';
// styles

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const { userDetails, setUserDetails } = useAccount();
    const [isLoading, setLoading] = useState(true);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
    }, [matchDownMd]);

    useEffect(() => {
        setLoading(false);
        getMyUserDetailsHandler();

    }, []);


    const getMyUserDetailsHandler = async () => {
        const res = await getMyUserDetails();

        if (res) {
            if (res.id) {
                getUserAccessOfUser(res.id)
            }
            dispatch({ type: USER_DATA, payload: res });
            console.log(res, 'resData');
            setUserDetails(res);

            const urlSearchParams = new URLSearchParams(window.location.search);
            let hostedID = urlSearchParams.get('hostedpage_id')
            if (hostedID) {
                handleAfterPurchase(hostedID, res?.id)
            }

        }
    };


    const removeSearchParams = (url) => {
        try {
            const urlObj = new URL(url);
            const params = new URLSearchParams(urlObj.search);
            params.delete('hostedpage_id');
            urlObj.search = params.toString();
            window.location.href = urlObj.toString()
            return urlObj.toString();
        } catch (error) {
            console.error('Invalid URL', error);
            return url;
        }
    };

    const handleAfterPurchase = async (hostID, UserID) => {

        console.log("userDetails=========", hostID, UserID);

debugger
        let payload = {
            hostedpage_id: hostID,
            user_id: UserID
        }
        const res = await createSubscription(payload);

        // if (res) {
        //     removeSearchParams(window.location.href);
        // }
        // if (res) {
        //     if (res.id) {
        //         getUserAccessOfUser(res.id)
        //     }
        // }
    }

    const getUserAccessOfUser = async (id) => {
        // const res = await getUserAccessScreenWise(id);
        // dispatch({ type: USER_ACCESS, payload: res });
        // console.log(res, 'USER_ACCESS');
    }



    return (
        <Box sx={{ display: 'flex', background: ' #fff' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={9}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                    padding: "10px",
                    zIndex: 9,
                    boxShadow: "0px 2px 8px 0px #0000001F",
                }}
                className="app_bar"
            >
                {/* <Toolbar> */}
                <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                {/* </Toolbar> */}
            </AppBar>

            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            <Main theme={theme} open={leftDrawerOpened}>
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
        </Box>
        // <ContractLibrary />
    );
};

export default MainLayout;
