
import React, { useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
    CircularProgress,
    List,
    ListItem,
    ListItemButton,
} from '@mui/material';
import Toast from 'components/Toast';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignModuleToRole, assignScreenToModule, deleteRoleModule, getAllModule, getAllScreen, getAssignScreenOfModule, getSingleOrgRoleModule, getSingleRoleModuleData, unAssignScreenFromModule } from 'services/UserAccessMasterService';
import defultPlholder from 'assets/images/defaultImg.png';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { assignAdminModuleToAdminRole, assignAdminRoleTplan, getAdminAllModule, getAssignAdminRoleToPlan, getSingleAdminRoleModuleData, getSuperAdminRole, removeAssignAdminRoleTplan } from 'services/AdminAcessService';
import NewModalComponent from 'components/NewModalComponent';
import UserModalToolbar from 'views/NewMatter/UserModalToolbar';
import Loader from 'ui-component/Loader';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Role Name', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignRoleToPlanPopup({ id, handleClose, open }) {
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [screenData, SetScreenData] = useState([])
    const [assignedScreen, setAssignedScreen] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignModuleOfRole()
        fetchAdminRole()
    }, [])


    const fetchAdminRole = async () => {
        setIsLoading(true)
        const res = await getSuperAdminRole();

        if (res?.data) {
            SetScreenData(res?.data)
            setIsLoading(false)
        }
        else {
            SetScreenData([])
            setIsLoading(false)
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const fetchAssignModuleOfRole = async () => {
        const res = await getAssignAdminRoleToPlan(id);

        if (res?.data) {
            setAssignedScreen(res?.data)
            let roleIds = res?.data.role.id;
            debugger
            setSelected([roleIds]);
        }
        else {
            setAssignedScreen([])
            setSelected([]);
        }
    }


    //on check unchecked assign plan to user function
    const handleClick = async (event, roleId) => {

        if (event.target.checked === true) {
            const payload = {
                role_id: roleId,
                plan_code: id
            };
            setLoading(true)
            const res = await assignAdminRoleTplan(payload);

            if (res?.response?.status === 400) {
                setLoading(false)
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
            else {
                setLoading(false)
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignModuleOfRole()
            }
        }
        else {
            setLoading(true)
            let RemoveroleId = assignedScreen?.id
            const res = await removeAssignAdminRoleTplan(RemoveroleId);
            if (res.message === "Role plan mapping deleted successfully.") {
                setLoading(false)
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignModuleOfRole()
            }
            else {
                setLoading(false)
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
    };


    const filteredScreen = applySortFilter(screenData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    return (
        <>
            {loading && <Loader />}
            <NewModalComponent
                width="100%"
                maxWidth="350px"
                open={open}
                handleClose={handleClose}
            >
                <Stack>
                    <Stack px={3} pt={3} spacing={1}>
                        <Stack spacing={1}>
                            <h4> Roles</h4>
                        </Stack>

                        <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />
                        <Stack sx={{ height: "calc(100vh - 582px)", minHeight: '200px', overflow: 'auto', margin: '15px 0' }}>
                            {isLoading ?
                                <Stack alignItems={'center'} justifyContent={'center'} style={{ height: '100%' }}>
                                    <CircularProgress />
                                </Stack>
                                :
                                <List>
                                    {
                                        filteredScreen?.length ?
                                            filteredScreen.map((row) => {
                                                const { name, id } = row;
                                                const isItemSelected = selected.indexOf(id) !== -1;
                                                return (

                                                    <ListItem disablePadding
                                                        className="filter-list-item customcheck"
                                                        key={row.id}
                                                    >
                                                        <ListItemButton disablePadding sx={{ padding: '0 5px', }} >
                                                            <input type="checkbox"
                                                                id={name}
                                                                checked={isItemSelected}
                                                                onChange={(event) => {
                                                                    handleClick(event, row?.id)
                                                                }}
                                                            />
                                                            <label htmlFor={name} style={{ width: '100%', justifyContent: 'flex-start' }}
                                                            > {name || '-'} </label>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })
                                            :
                                            isUserNotFound && (
                                                <li>
                                                    <Stack align="center" colSpan={4} sx={{ py: 3 }}>
                                                        <SearchNotFound searchQuery={filterName} />
                                                    </Stack>
                                                </li>
                                            )
                                    }
                                </List>

                            }
                        </Stack>

                    </Stack>

                    <Stack
                        className='updatelicense_btn'
                        direction="row" justifyContent="flex-end" alignItems="center" gap={3} style={{ padding: '10px', borderTop: '1px solid #D1D9E0' }}>
                        <button
                            className='outline_btn' color="primary" onClick={handleClose}
                            style={{ width: '100%' }}
                        >
                            Cancel
                        </button>
                    </Stack>
                </Stack>
            </NewModalComponent>
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignRoleToPlanPopup