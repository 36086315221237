import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, Paper, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, TableHead, Tab, OutlinedInput, Checkbox, ListItemText, Tooltip, IconButton, Autocomplete, tableCellClasses } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import Iconify from 'components/Iconify';
import ModalComponent from 'components/Modal';
import { getAllClient, getMatterByClient } from 'services/ClientService';
import DataListToolbar from '../DataListToolbar';
import DataMoreMenu from '../DataMoreMenu';
import { assignValueToData, createDataRole, deleteDataRole, getDataList, getDataRoleList, getDataRoleValue, getOrgDataItemList, selectDataItem, unSelectDataItem, updateDataItem, updateDataRole } from 'services/DataRoleService';
import { getAllMatters } from 'services/MatterService';
import AssignClienMatterPopup from '../AssignClienMatterPopup';
import AssignDesignationPopup from '../AssignDesignationPopup';
import AssignPracticeAreaPopup from '../AssignPracticeAreaPopup';
import AssignDepartmentPopup from '../AssignDepartmentPopup';
import AssignModulePopup from '../AssignModulePopup';
import AssignDataRoleToUserPopup from '../AssignDataRoleToUserPopup';
import { MdOutlineInfo } from "react-icons/md";
import { useAccount } from 'context/AccountProvider';
import StyledTableListHead from 'views/StyledTable/StyledTableListHead';
import { CustomIOSSwitch } from 'components/ISOSwitch';
import moment from 'moment';
import NewModalComponent from 'components/NewModalComponent';
import DataRoleStyledTableListHead from './DataRoleStyledTableListHead';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));




const TABLE_HEAD = [
    { id: 'name', label: 'Data Role Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'created', label: 'created on', alignRight: false },
    { id: 'client_matter', label: 'Client & matter', alignRight: false },
    { id: 'department', label: 'Department', alignRight: false },
    { id: 'designation', label: 'Designation', alignRight: false },
    { id: 'practise_area', label: 'Practice Area', alignRight: false },
    { id: 'module', label: 'Modules', alignRight: false },
    { id: 'Assignee', label: 'Assignee', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function DataRole() {
    const { userDetails } = useAccount();
    const [list, setList] = useState([])
    const [dataList, setDataList] = useState([])
    const [selectedDataList, setselectedDataList] = useState([])
    const [orgRoleData, setOrgRoleData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [createModal, setCreateModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [module, setModule] = useState(false)
    const [userModule, setUserModule] = useState(false)
    const [matterClientModule, setMatterClientModule] = useState(false)
    const [departmentModule, setDepartmentModule] = useState(false)
    const [designationModule, setDesignationModule] = useState(false)
    const [practiceAreaModule, setPracticeAreaModule] = useState(false)
    const [dataListName, setdataListName] = React.useState([]);
    const [showDD, setShowDD] = useState(false)
    const divRef = useRef(null);
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });


    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    const [activeDataRoleId, setActiveDataRoleId] = useState('')
    const [orgDataRoleValue, setOrgDataRoleValue] = useState({
        name: '',
        status: 'INACTIVE',
    });

    const [prevdataRoleData, setprevDataRoleData] = useState([])
    const [allClientList, setAllClientList] = useState([])
    const [matterList, setMatterList] = useState([])


    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgRoleData?.length) : 0;

    const filterCoupon = applySortFilter(orgRoleData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterCoupon?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orgRoleData?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handelCloseModal = () => {
        setOrgDataRoleValue({
            name: '',
            status: 'INACTIVE',
        })
        setModule(false)
        setUserModule(false)
        setMatterClientModule(false)
        setDepartmentModule(false)
        setDesignationModule(false)
        setPracticeAreaModule(false)
        setCreateModal(false)
        setIsEdit(false)
        setClient_matter([
            {
                "client_id": '',
                "matter_id": ''
            }
        ])
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrgDataRoleValue((prev) => ({ ...prev, [name]: value }));
    };


    useEffect(() => {
        getAllMatterHandler()
        fetchAllDataRole()
        getSelectedDataItem()
        fetchAllDataRole()
        fetchAllClient()
        fetchDataMasterData()
    }, [])


    const fetchDataMasterData = async () => {
        const res = await getDataList();
        if (res?.data) {
            setDataList(res?.data)
        }
        else {
            setDataList([])
            setToastData({
                open: true,
                status: 'success',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    console.log("dataList", dataList);
    /// fetch org data list
    const getSelectedDataItem = (async () => {
        const res = await getOrgDataItemList();
        if (res.data.length > 0) {
            debugger
            const values = res.data.map(item => item?.data_access_field?.value);
            const selectedValue = res.data.map(item => item?.data_access_field.id);
            console.log("selectedValue", selectedValue);
            setselectedDataList(res.data)
            setdataListName(selectedValue)
            setList(values)
        }
    });


    //fetch all module data
    const fetchAllDataRole = async () => {
        const res = await getDataRoleList();
        if (res?.data) {
            setOrgRoleData(res?.data)
        }
        else {
            setOrgRoleData([])
        }
    }


    //fetch all client list
    const fetchAllClient = async () => {
        const res = await getAllClient();
        if (res) { setAllClientList(res) }
        else { setAllClientList([]) }
    }


    // get client wise matter list 
    const getAllMatterHandler = async () => {
        const res = await getAllMatters();
        console.log(res, 'resMatter');
        if (res && res.length > 0) {
            setMatterList(res);
        } else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
        }
    };


    //find changes
    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };


    //handle create uodate data role 
    const handleCreateUpdateData = async (event) => {
        event.preventDefault()
        let payload = { ...orgDataRoleValue }
        if (isEdit) {
            const newChangedKeys = findChangedData(prevdataRoleData, orgDataRoleValue);
            const res = await updateDataRole(orgDataRoleValue?.id, newChangedKeys);
            if (res?.message === "Organization Data role updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchAllDataRole();
                setIsEdit(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            const res = await createDataRole(payload);
            if (res.message === "Organization Data Role created successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchAllDataRole();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }

        }
    }


    // handle Switch
    const handleSwitch = (name, value) => {
        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setOrgDataRoleValue((prev) => ({ ...prev, [name]: status }));
    }

    //handle table toggle 
    const toggleActive = async (e, id) => {
        const tempScreenData = [...orgRoleData];
        tempScreenData.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    status: el.status,
                };
                const res = await updateDataRole(id, payload);
                console.log(res, 'resData');
                debugger
                if (res.message === "Organization Data role updated successfully.") {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    fetchAllDataRole();
                }
                else {
                    fetchAllDataRole();
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };

    // handle remove data role 
    const removeOrgDataRole = async (data) => {
        if (data?.status === 'INACTIVE') {
            const res = await deleteDataRole(data.id);
            if (res.message === "Organization data role deleted successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                fetchAllDataRole();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
        else {
            setToastData({
                open: true,
                status: 'warning',
                message: `To Delete, First Deactivate The Role Status.`
            });
        }
    }

    // edit data role
    const EditDataStatus = (id, data) => {
        setIsEdit(true)
        setCreateModal(true)
        setOrgDataRoleValue(data)
        setprevDataRoleData(data)
    }

    const [client_matter, setClient_matter] = useState([
        {
            "client_id": '',
            "matter_id": ''
        }
    ])




    //handle select datalist
    const handleClick = async (event, id) => {
        debugger
        if (event.target.checked === true) {
            const res = await selectDataItem(userDetails?.organisation?.id, id);
            debugger
            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
            else {
                const selectedIndex = selected.indexOf(id);
                let newSelected = [];
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selected, id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(selected.slice(1));
                } else if (selectedIndex === selected.length - 1) {
                    newSelected = newSelected.concat(selected.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
                }
                debugger
                setSelected(newSelected);
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                getSelectedDataItem()
            }
        }
        else {
            debugger
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
            setSelected(newSelected);

            let deleteId = selectedDataList?.filter((_) => _?.data_access_field?.id === id)[0].id

            const res = await unSelectDataItem(deleteId);

            setToastData({
                open: true,
                status: 'success',
                message: res?.response?.data?.message || res.message
            });
            getSelectedDataItem()
        }
    };

    //hide dropdown on outside click
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setShowDD(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            {console.log("client_matter", client_matter)}
            {IsLoading && <Loader />}
            <Card padding={5}>
                <Stack className='breadcrubs' justifyItems={'flex-start'} alignItems={'flex-start'}>
                    <h5> Data Role </h5>

                    <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} sx={{ width: '100%' }}>
                        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignContent={'center'} sx={{ width: '100%' }} gap={1}>
                            <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

                            <div className='select_datalist'>
                                <label onClick={() => setShowDD(!showDD)}>
                                    Select DataList
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 15C11.084 15 10.224 14.644 9.57603 13.996L5.15803 9.86503C4.95703 9.67703 4.94603 9.36003 5.13503 9.15803C5.32303 8.95703 5.64003 8.94603 5.84203 9.13503L10.272 13.278C11.213 14.217 12.799 14.206 13.717 13.29L18.158 9.13603C18.36 8.94803 18.677 8.95803 18.865 9.15903C19.053 9.36103 19.043 9.67803 18.842 9.86603L14.412 14.009C13.776 14.645 12.916 15.001 12 15.001V15Z" fill="#606060" />
                                    </svg>
                                </label>
                                <ul className={`select_datalist_dd ${showDD ? 'active' : ''}`} ref={divRef}>
                                    {console.log("dataListName", dataListName, dataList, selectedDataList)}
                                    {dataList?.map((variant) => (
                                        <li key={variant.id} value={variant}>
                                            <Checkbox
                                                checked={dataListName.indexOf(variant.id) !== -1}
                                                onClick={(event) => handleClick(event, variant.id)}
                                            />
                                            <ListItemText primary={variant.label} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <Tooltip title="Select datalist where you want to apply data access">
                                <IconButton>
                                    <MdOutlineInfo />
                                </IconButton>
                            </Tooltip>
                        </Stack>

                        <button className='blue_btn' style={{ width: "220px" }} onClick={() => setCreateModal(!createModal)} >
                            Create Data Role
                        </button>
                    </Stack>
                </Stack>


                {/* <DataListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                {/* <CardContent > */}
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 400, marginTop: "20px" }}>
                        <Table
                            sx={{
                                background: 'white',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                border: '1px solid #D1D9E0',
                                borderCollapse: 'unset',
                                overflow: 'hidden'
                            }}
                        >
                            <DataRoleStyledTableListHead
                                rowlist={list}
                            />

                            <TableBody>
                                {filterCoupon?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                    const { id, name, status, created } = row;
                                    const isItemSelected = selected.indexOf(name) !== -1;
                                    return (
                                        <StyledTableRow
                                            // key={id}
                                            tabIndex={-1}
                                        >
                                            <StyledTableCell align="left"> {name ? name : '-'} </StyledTableCell>
                                            <StyledTableCell align="left" >
                                                <CustomIOSSwitch
                                                    handleSwitch={(e) => toggleActive(e, id)}
                                                    status={status}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="left"> {created ? moment(created).format('YYYY-MM-DD') : '-'} </StyledTableCell>

                                            {list?.includes('client_matter') &&
                                                <StyledTableCell align="left">
                                                    {/* {!row?.module?.length ? */}
                                                    <Button
                                                        // startIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" /> </svg>}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setMatterClientModule(true)
                                                        }}
                                                        color="primary"
                                                    >
                                                        Assign/View Client & Matter
                                                    </Button>

                                                    {/* :
                                                    <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                        sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setMatterClientModule(true)
                                                        }}
                                                    >
                                                        Client & Matter Assigned
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1839_74338)">
                                                                <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1839_74338">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Stack>
                                                } */}
                                                </StyledTableCell>
                                            }

                                            {list?.includes('department') &&
                                                <StyledTableCell align="left">
                                                    {/* {!row?.module?.length ? */}
                                                    <Button
                                                        // startIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" /> </svg>}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setDepartmentModule(true)
                                                        }}
                                                        color="primary"
                                                    >
                                                        Assign/View Department
                                                    </Button>
                                                    {/* 
                                                    :
                                                    <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                        sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setDepartmentModule(true)
                                                        }}
                                                    >
                                                        Department Assigned
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1839_74338)">
                                                                <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1839_74338">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Stack>
                                                } */}
                                                </StyledTableCell>
                                            }

                                            {list?.includes('designation') &&
                                                <StyledTableCell align="left">
                                                    {/* {!row?.module?.length ? */}
                                                    <Button
                                                        // startIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" /> </svg>}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setDesignationModule(true)
                                                        }}
                                                        color="primary"
                                                    >
                                                        Assign/View Designation
                                                    </Button>
                                                    {/* 
                                                    :
                                                    <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                        sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setDesignationModule(true)
                                                        }}
                                                    >
                                                        Designation Assigned
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1839_74338)">
                                                                <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1839_74338">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Stack>
                                                } */}
                                                </StyledTableCell>
                                            }

                                            {list?.includes('practise_area') &&
                                                <StyledTableCell align="left">
                                                    {/* {!row?.module?.length ? */}
                                                    <Button
                                                        // startIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" /> </svg>}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setPracticeAreaModule(true)
                                                        }}
                                                        color="primary"
                                                    >
                                                        Assign/View Practice Area
                                                    </Button>

                                                    {/* :
                                                    <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                        sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setPracticeAreaModule(true)
                                                        }}
                                                    >
                                                        Practice Area Assigned
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1839_74338)">
                                                                <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1839_74338">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Stack>
                                                } */}
                                                </StyledTableCell>
                                            }

                                            <StyledTableCell align="left">
                                                {/* {!row?.module?.length ? */}
                                                <Button
                                                    // startIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" /> </svg>}
                                                    onClick={() => {
                                                        setActiveDataRoleId(id)
                                                        setModule(true)
                                                    }}
                                                    color="primary"
                                                >
                                                    Assign/view Module
                                                </Button>

                                                {/* :
                                                    <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                        sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setModule(true)
                                                        }}
                                                    >
                                                        Module Assigned
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1839_74338)">
                                                                <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1839_74338">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Stack>
                                                } */}
                                            </StyledTableCell>



                                            <StyledTableCell align="left">
                                                {/* {!row?.module?.length ? */}
                                                <Button
                                                    // startIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" /> </svg>}
                                                    onClick={() => {
                                                        setActiveDataRoleId(id)
                                                        setUserModule(true)
                                                    }}
                                                    color="primary"
                                                >
                                                    Assign/View Users
                                                </Button>
                                                {/* 
                                                    :
                                                    <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                        sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setUserModule(true)
                                                        }}
                                                    >
                                                        Users Assigned
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1839_74338)">
                                                                <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1839_74338">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Stack>
                                                } */}
                                            </StyledTableCell>


                                            {/* <StyledTableCell align="left" >
                                                <Stack gap={1} direction={'row'}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setUserModule(true)
                                                        }}
                                                        color="primary"
                                                    >
                                                        Assign To User
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            setActiveDataRoleId(id)
                                                            setModule(true)
                                                        }}
                                                        color="primary"
                                                    >
                                                        Assign Module
                                                    </Button>

                                                    {list?.includes('client_matter') &&
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setActiveDataRoleId(id)
                                                                setMatterClientModule(true)
                                                            }}
                                                            color="primary"
                                                        >
                                                            Assign Client & Matter
                                                        </Button>
                                                    }

                                                    {list?.includes('department') &&
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setActiveDataRoleId(id)
                                                                setDepartmentModule(true)
                                                            }}
                                                            color="primary"
                                                        >
                                                            Assign Department
                                                        </Button>
                                                    }
                                                    {list?.includes('designation') &&
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setActiveDataRoleId(id)
                                                                setDesignationModule(true)
                                                            }}
                                                            color="primary"
                                                        >
                                                            Assign Designation
                                                        </Button>
                                                    }
                                                    {list?.includes('practise_area') &&
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setActiveDataRoleId(id)
                                                                setPracticeAreaModule(true)
                                                            }}
                                                            color="primary"
                                                        >
                                                            Assign Practice Area
                                                        </Button>
                                                    }
                                                </Stack>
                                            </StyledTableCell> */}

                                            <StyledTableCell align="right">
                                                <DataMoreMenu
                                                    onEdit={EditDataStatus}
                                                    id={id}
                                                    data={row}
                                                    removeData={removeOrgDataRole}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <StyledTableRow style={{ height: 53 * emptyRows }}>
                                        <StyledTableCell colSpan={13} />
                                    </StyledTableRow>
                                )}
                            </TableBody>
                            {isDataNotFound && (
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
                {/* </CardContent> */}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={orgRoleData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>


            <NewModalComponent
                width="100%"
                maxWidth="350px"
                open={createModal}
                handleClose={() => handelCloseModal()}
            >
                <Card>
                    <Stack px={3} pt={3} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Stack mt={1}>
                            <h4>{isEdit ? 'Update' : 'Create'} Data Role</h4>
                        </Stack>
                        <form
                            onSubmit={handleCreateUpdateData}
                        >

                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                label="Name"
                                variant="outlined"
                                value={orgDataRoleValue?.name}
                                name="name"
                                onChange={handleChange}
                                required
                            />
                            <Stack direction="row"
                                spacing={2}
                                mb={2}
                                mt={2}
                                sx={{
                                    border: ' 1px solid #D1D9E0',
                                    borderRadius: '10px',
                                    padding: '10px 14px'
                                }}
                                alignItems='center'
                                justifyContent={'space-between'}
                            >
                                <label style={{ color: '#606060', fontWeight: '400' }}> Status </label>

                                <CustomIOSSwitch
                                    handleSwitch={(e) => handleSwitch('status', e.target.checked)}
                                    status={orgDataRoleValue?.status}
                                />
                            </Stack>

                            <Stack
                                className='updatelicense_btn'
                                flexDirection={'row'}
                                justifyContent={'flex-end'}
                                gap={1} style={{ padding: "14px 0", paddingTop: "0", width: "100%" }}>
                                <button className='outline_btn' onClick={handelCloseModal}>
                                    Cancel
                                </button>
                                <button
                                    className='blue_btn'
                                    sx={{ width: "100%" }}
                                    type='submit'
                                >
                                    {isEdit ? "Update" : "Create"}
                                </button>
                            </Stack>

                        </form>
                    </Stack>
                </Card>
            </NewModalComponent >




            {userModule &&
                <AssignDataRoleToUserPopup
                    open={userModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }


            {module &&
                <AssignModulePopup
                    open={module}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }

            {matterClientModule &&
                <AssignClienMatterPopup
                    open={matterClientModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                    allClientList={allClientList}
                    allMatterList={matterList}
                />
            }

            {departmentModule &&
                <AssignDepartmentPopup
                    open={departmentModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }

            {designationModule &&
                <AssignDesignationPopup
                    open={designationModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }

            {practiceAreaModule &&
                <AssignPracticeAreaPopup
                    open={practiceAreaModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }


            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>
    )
}

export default DataRole