import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, CardContent, Paper, FormControlLabel, Switch, tableCellClasses } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import Iconify from 'components/Iconify';
import { createOrgPracticeArea, deleteOrgPracticeArea, deleteScreen, getAllModule, getAllOrgPracticeArea, getAllSAPracticeArea, getUserAccessProfileWise, updateOrgPracticeArea } from 'services/UserAccessMasterService';
import OrgPracticeAreaMoreMenu from './OrgPracticeAreaMoreMenu';
import AssignPracticeAreaToRole from './AssignPracticeAreaToRole';
import { useAccount } from 'context/AccountProvider';
import StyledTableListHead from 'views/StyledTable/StyledTableListHead';
import { CustomIOSSwitch } from 'components/ISOSwitch';
import NewModalComponent from 'components/NewModalComponent';
import AssignPracticeAreaToDataRole from './AssignPracticeAreaToDataRole';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));


const TABLE_HEAD = [
    { id: 'name', label: 'Practice Area Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    // { id: 'role', label: 'Screen Role', alignRight: false },
    // { id: '', label: 'Data Role', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function OrgPracticeAreaMaster() {
    const { userDetails } = useAccount();
    const [orgPracticeAreaData, SetOrgPracticeAreaData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [createModal, setCreateModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [assignModal, setAssignModal] = useState(false)
    const [orgPracticeAreaId, setOrgPracticeAreaId] = useState('')
    const [assignDataRoleModal, setAssignDataRoleModal] = useState(false)
    const [adminValue, setAdminValue] = useState('')
    const [saPracticeAreaData, setSaPracticeAreaData] = useState([])


    const [moduleDataValue, setModuleDataValue] = useState({
        name: '',
        status: 'INACTIVE',
    });
    const [prevmoduleData, setprevModuleData] = useState([])
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    useEffect(() => {
        fetchOrgPracticeAreaData()
        fetchSAPracticeAreaData()
    }, [])

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgPracticeAreaData?.length) : 0;

    const filterCoupon = applySortFilter(orgPracticeAreaData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterCoupon?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orgPracticeAreaData?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setModuleDataValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleSwitch = (name, value) => {
        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setModuleDataValue((prev) => ({ ...prev, [name]: status }));
    }



    //fetch all Organization practice area
    const fetchSAPracticeAreaData = async () => {
        const res = await getAllSAPracticeArea();
        if (res?.data) {
            setSaPracticeAreaData(res?.data)
        }
        else {
            setSaPracticeAreaData([])
        }
    }


    //fetch all Organization practice area
    const fetchOrgPracticeAreaData = async () => {
        const res = await getAllOrgPracticeArea();
        if (res?.data) {
            SetOrgPracticeAreaData(res?.data)
        }
        else {
            SetOrgPracticeAreaData([])
        }
    }



    const EditModuleStatus = (id, data) => {
        setIsEdit(true)
        setModuleDataValue(data)
        setprevModuleData(data)
        setCreateModal(true)
    }

    const handelCloseModal = () => {
        setModuleDataValue({
            name: '',
            status: 'INACTIVE',
        })
        setCreateModal(false)
        setIsEdit(false)
        setOrgPracticeAreaId('')
        setAssignModal(false)
        setAssignDataRoleModal(false)
    }


    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };


    // handle create update module
    const handleCreateUpdateModule = async (event) => {
        event.preventDefault()

        let payload = {
            ...moduleDataValue,
            status: moduleDataValue?.status,
        }

        if (isEdit) {

            // const newChangedKeys = findChangedData(prevmoduleData, moduleDataValue);
            setIsLoading(true)
            const res = await updateOrgPracticeArea(moduleDataValue?.id, moduleDataValue);
            if (res?.message === "Practice Area data updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchOrgPracticeAreaData();
                setIsEdit(false)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            setIsLoading(true)
            const res = await createOrgPracticeArea(payload);
            if (res.message === "Practice Area data created successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchOrgPracticeAreaData();
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
    }


    //handle table toggle 
    const toggleActive = async (e, id) => {
        const tempOrgPracticeAreaData = [...orgPracticeAreaData];
        tempOrgPracticeAreaData.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    ...el,
                    status: el.status,
                };
                setIsLoading(true)
                const res = await updateOrgPracticeArea(id, payload);
                console.log(res, 'resData');
                if (res.message === "Practice Area data updated successfully.") {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    fetchOrgPracticeAreaData();
                    setIsLoading(false)
                }
                else {
                    fetchOrgPracticeAreaData();
                    setIsLoading(false)
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };

    const removeModuleData = async (id) => {
        const res = await deleteOrgPracticeArea(id);
        if (res.message === "Practice Area data deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchOrgPracticeAreaData();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }




    // const AssignOrgRole = (id) => {
    //     setAssignModal(true)
    //     setOrgPracticeAreaId(id)
    // }

    // const AssignOrgDataRole = (id) => {
    //     setAssignDataRoleModal(true)
    //     setOrgPracticeAreaId(id)
    // }





    return (
        <>
            {IsLoading && <Loader />}
            <Card padding={5}>

                <Stack className='breadcrubs' justifyItems={'flex-start'} alignItems={'flex-start'}>
                    <Stack mt={1} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} sx={{ width: '100%' }}>
                        <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                        <button className='blue_btn' onClick={() => setCreateModal(!createModal)} >
                            Create Practice Area
                        </button>

                    </Stack>
                </Stack>

                <Scrollbar>
                    <TableContainer sx={{ minWidth: 400, marginTop: "20px" }}>
                        <Table
                            sx={{
                                background: 'white',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                border: '1px solid #D1D9E0',
                                borderCollapse: 'unset',
                                overflow: 'hidden'
                            }}>
                            <StyledTableListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={orgPracticeAreaData?.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filterCoupon?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                    const { id, name, status } = row;
                                    const isItemSelected = selected.indexOf(name) !== -1;
                                    return (
                                        <StyledTableRow
                                            hover
                                            // key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <StyledTableCell align="left"> {name ? name : '-'} </StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '61px' }}>
                                                <CustomIOSSwitch
                                                    handleSwitch={(e) => toggleActive(e, id)}
                                                    status={status}
                                                />
                                            </StyledTableCell>

                                            {/* <StyledTableCell align="left" >
                                                <Button
                                                    // startIcon={
                                                    //     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    //         <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" />
                                                    //     </svg>
                                                    // }
                                                    onClick={() => AssignOrgRole(id)}
                                                    color="primary"
                                                >
                                                    Assign/View Role
                                                </Button>
                                            </StyledTableCell>

                                            <StyledTableCell align="left" >
                                                <Button
                                                    // startIcon={
                                                    //     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    //         <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" />
                                                    //     </svg>
                                                    // }
                                                    onClick={() => AssignOrgDataRole(id)}
                                                    color="primary"
                                                >
                                                    Assign/View Data Role
                                                </Button>
                                            </StyledTableCell> */}

                                            <StyledTableCell align="center" sx={{ width: '61px' }}>
                                                <OrgPracticeAreaMoreMenu
                                                    onEdit={EditModuleStatus}
                                                    id={id}
                                                    data={row}
                                                    removeData={removeModuleData}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <StyledTableRow style={{ height: 53 * emptyRows }}>
                                        <StyledTableCell colSpan={13} />
                                    </StyledTableRow>
                                )}
                            </TableBody>
                            {isDataNotFound && (
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={orgPracticeAreaData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card >


            <NewModalComponent
                width="100%"
                maxWidth="410px"
                open={createModal}
                handleClose={() => handelCloseModal()}
            >

                <Stack px={3} pt={3} direction="column" justifyContent="space-between" spacing={1} >
                    <Stack mt={1} fullWidth>
                        <h4 style={{ textAlign: 'left', width: '100%', fontWeight: '700' }}>{isEdit ? 'Update' : 'Create'} Practice Area</h4>
                    </Stack>
                    <form onSubmit={handleCreateUpdateModule}>

                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            label="Practice Area Name"
                            variant="outlined"
                            value={moduleDataValue?.name}
                            name="name"
                            onChange={handleChange}
                            required
                        />

                        <div className='predefinedvalue'>
                            <label> Select Practice Area Name from the List </label>
                            <ul>
                                {saPracticeAreaData?.filter((_) => _?.status === 'ACTIVE').length ?
                                    saPracticeAreaData?.filter((_) => _?.status === 'ACTIVE')?.map((item, i) => {
                                        return (
                                            <li key={i} className={adminValue === item?.name ? 'active' : ''}
                                                onClick={() => {
                                                    setAdminValue(item?.name);
                                                    setModuleDataValue((prev) => ({ ...prev, name: item?.name }));
                                                }}
                                            >
                                                {item?.name}
                                            </li>
                                        )
                                    })
                                    :
                                    <li> No Data Found </li>
                                }
                            </ul>
                        </div>


                        <Stack direction="row"
                            spacing={2}
                            mb={2}
                            mt={2}
                            sx={{
                                border: ' 1px solid #D1D9E0',
                                borderRadius: '10px',
                                padding: '10px 14px'
                            }}
                            alignItems='center'
                            justifyContent={'space-between'}
                        >
                            <label style={{ color: '#606060', fontWeight: '400' }}> Status </label>

                            <CustomIOSSwitch
                                handleSwitch={(e) => handleSwitch('status', e.target.checked)}
                                status={moduleDataValue?.status}
                            />
                        </Stack>


                        <Stack
                            className='updatelicense_btn'
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            gap={1} style={{ padding: "14px 0", paddingTop: "0", width: "100%" }}>
                            <button className='outline_btn' onClick={handelCloseModal}>
                                Cancel
                            </button>
                            <button
                                className='blue_btn'
                                sx={{ width: "100%" }}
                                type='submit'
                            >
                                {isEdit ? "Update" : "Create"}
                            </button>
                        </Stack>

                    </form>
                </Stack>

            </NewModalComponent >

            {/* {assignModal && <AssignPracticeAreaToRole id={orgPracticeAreaId} open={assignModal} handleClose={() => handelCloseModal()} />}

            {assignDataRoleModal && <AssignPracticeAreaToDataRole id={orgPracticeAreaId} open={assignDataRoleModal} handleClose={() => handelCloseModal()} />} */}

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>

    )
}

export default OrgPracticeAreaMaster