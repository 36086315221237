import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Tab } from '@mui/material';
import React, { Component } from 'react';
import OrgdepartmentMaster from '../OrgdepartmentMaster';
import OrgdesignationMaster from '../OrgdesignationMaster';
import OrgPracticeAreaMaster from '../OrgPracticeAreaMaster';
import { useAccount } from 'context/AccountProvider';

const Setup = () => {
    const { userDetails } = useAccount()

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Card padding={5}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" >
                            <Tab label="Designation" value="1" sx={{ fontSize: '20px' }} />
                            {userDetails?.organisation?.org_type === 'enterprise' ?
                                <Tab label="Department" value="2" sx={{ fontSize: '20px' }} />
                                :
                                <Tab label="Practice Area" value="3" sx={{ fontSize: '20px' }} />
                            }
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: '10px' }}>
                        <OrgdesignationMaster />
                    </TabPanel>
                    {userDetails?.organisation?.org_type === 'enterprise' ?
                        <TabPanel value="2" sx={{ padding: '10px' }}>
                            <OrgdepartmentMaster />
                        </TabPanel>
                        :
                        <TabPanel value="3" sx={{ padding: '10px' }}>
                            <OrgPracticeAreaMaster />
                        </TabPanel>
                    }
                </TabContext>
            </Box>
        </Card>
    );
};


export default Setup;