/* eslint-disable no-else-return */

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as React from 'react';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TextField,
    FormControlLabel,
    Switch,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TableHead,
    IconButton,
    TextareaAutosize,
    tableCellClasses
} from '@mui/material';
// components
// import Page from '../../../../components/Page';
import Label from '../..';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect } from 'react';

import { useState } from 'react';
import { addProductToPlan, createPlan, getAllPlans, getProductsLinkToPlan, updatePlan, uploadImage } from 'services/PlanService';
import ModalComponent from 'components/Modal';
import { getAllProducts } from 'services/ProductService';
import { useCallback } from 'react';
import { Box } from '@mui/system';
import { useAccount } from 'context/AccountProvider';
import { createUserOrganization } from 'services/UserService';
import CloseIcon from '@mui/icons-material/Close';
import defultPlholder from 'assets/images/defaultImg.png';
import PlanListToolbar from './PlanListToolbar';
import StyledTableListHead from 'views/StyledTable/StyledTableListHead';
import { getAllZohoPlan, getNotificationData } from 'services/ZohoSubscriptions';
import AssignRoleToPlanPopup from './AssignRoleToPlanPopup';
import { CustomIOSSwitch } from 'components/ISOSwitch';
import Toast from 'components/Toast';

// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));



const TABLE_HEAD = [
    { id: 'name', label: 'Plan Name', alignRight: false },
    { id: 'plan_image', label: 'Plan Image', alignRight: false },
    { id: 'interval_unit', label: 'Plan Frequency', alignRight: false },
    // { id: 'license', label: 'License', alignRight: false },
    { id: 'cf_user_type', label: 'Plan Type', alignRight: false },
    { id: 'is_active', label: 'Status', alignRight: false },
    { id: 'recurring_price_formatted', label: 'Price', alignRight: false },
    { id: '', label: 'Assign/view Roles', alignRight: false },
    // { id: '', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Plans() {
    const { userDetails } = useAccount();
    const navigate = useNavigate();
    // console.log(JSON.stringify(USERLIST))
    const [page, setPage] = React.useState(0);

    const [order, setOrder] = React.useState('asc');

    const [selected, setSelected] = React.useState([]);

    const [orderBy, setOrderBy] = React.useState('name');

    const [filterName, setFilterName] = React.useState('');

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isAdd, setIsAdd] = React.useState(false);
    const [UD, setUD] = React.useState([]);
    const [allPlans, setAllPlans] = useState([]);
    const [activePlanId, setActivePlanId] = useState()
    const [assignModal, setAssignModal] = useState(false)
    const [allProducts, setAllProducts] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState('');
    const [notificationData, setNotificationData] = useState([])

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const getAllPlansHandler = async () => {
        const res = await getAllZohoPlan();
        console.log(res);
        if (res.data) {

            setAllPlans(res.data);
        }
        else {
            setAllPlans([])
        }
    };

    const getProductsLinkToPlanHanlder = useCallback(async () => {
        const res = await getProductsLinkToPlan(selectedPlanId);
        setLinkedProductList(res);
    }, [selectedPlanId]);

    useEffect(() => {
        if (selectedPlanId) {
            getProductsLinkToPlanHanlder();
        }
    }, [selectedPlanId]);
    useEffect(() => {
        getAllPlansHandler();
    }, []);





    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - UD.length) : 0;

    const filteredPlans = applySortFilter(allPlans, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredPlans.length === 0;

    const togglePlanActive = async (e, id) => {
        const tempPlans = [...allPlans];
        let status = false;

        tempPlans.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = true;
                    status = true;
                } else {
                    status = false;
                    el.status = false;
                }
                const payload = {
                    name: el.name,
                    status: el.status,
                };
                const res = await updatePlan(id, payload);
                console.log(res, 'resData');
            }
        });

        setAllPlans(tempPlans);
    };


    const handelCloseModal = () => {
        setAssignModal(false)
        setAssignModal('')
    }




    const handleRefreshPlan = async () => {
        const res = await getNotificationData();
        console.log(res);
        debugger
        if (res.data) {
            setNotificationData(res.data);
            setToastData({
                open: true,
                status: 'success',
                message: res?.response?.data?.message || res.message
            });
        }
        else {
            setNotificationData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }







    return (
        <>
            {/* <Container> */}



            <Card padding={5} sx={{ height: '100%' }}>

                <Stack className='breadcrubs' justifyItems={'flex-start'} alignItems={'flex-start'}>
                    <h5> Plans </h5>

                    <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} sx={{ width: '100%' }}>
                        <Stack flexDirection={'row'} gap={2}>
                            <PlanListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                            <div className='notification'>
                                {/* <button className='notification_btn'>
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 17V15H2V8C2 6.61667 2.41667 5.3875 3.25 4.3125C4.08333 3.2375 5.16667 2.53333 6.5 2.2V1.5C6.5 1.08333 6.64583 0.729167 6.9375 0.4375C7.22917 0.145833 7.58333 0 8 0C8.41667 0 8.77083 0.145833 9.0625 0.4375C9.35417 0.729167 9.5 1.08333 9.5 1.5V2.2C10.8333 2.53333 11.9167 3.2375 12.75 4.3125C13.5833 5.3875 14 6.61667 14 8V15H16V17H0ZM8 20C7.45 20 6.97917 19.8042 6.5875 19.4125C6.19583 19.0208 6 18.55 6 18H10C10 18.55 9.80417 19.0208 9.4125 19.4125C9.02083 19.8042 8.55 20 8 20ZM4 15H12V8C12 6.9 11.6083 5.95833 10.825 5.175C10.0417 4.39167 9.1 4 8 4C6.9 4 5.95833 4.39167 5.175 5.175C4.39167 5.95833 4 6.9 4 8V15Z" fill="#A0A0A0" />
                                    </svg>
                                </button> */}
                                {/* <div className='notification_model'>
                                    <h5>Plan Notification </h5>
                                    <ul className='notification_tabs'>
                                        <li className='active'> All </li>
                                        <li> Unread(2) </li>
                                    </ul>
                                    <ul className='notification_filter'>
                                        <li> Last 7 days</li>
                                    </ul>
                                    <div className='notification_content'>
                                        <ul>
                                            {notificationData.map((item, i) => {
                                                return (
                                                    <li key={i}>
                                                        <div className='notification_content_left'>
                                                            Plan {item.name} has been Pushed
                                                            <Stack flexDirection={'row'} gap={1.5}>
                                                                <button> Discard </button>
                                                                <button> Accept </button>
                                                            </Stack>
                                                        </div>
                                                        <div className='notification_content_right'>
                                                            JUN 12
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </Stack>

                        <button className='blue_btn' onClick={() => handleRefreshPlan()}>
                            Refresh
                        </button>

                    </Stack>
                </Stack>



                <Scrollbar>
                    <TableContainer sx={{ minWidth: 400, marginTop: "20px" }}>
                        <Table
                            sx={{
                                background: 'white',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                border: '1px solid #D1D9E0',
                                borderCollapse: 'unset',
                                overflow: 'hidden'
                            }}
                        >
                            <StyledTableListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                    const {
                                        name,
                                        image_name,
                                        interval_unit,
                                        is_active,
                                        id,
                                        status,
                                        recurring_price_formatted,
                                        plan_code
                                    } = row;


                                    const isItemSelected = selected.indexOf(name) !== -1;

                                    return (
                                        <StyledTableRow
                                            hover
                                            key={i}
                                            tabIndex={-1}
                                        >
                                            { }
                                            <StyledTableCell >
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                                    <Typography noWrap>{name}</Typography>
                                                </Stack>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {image_name ?
                                                    <img src={image_name} width={50} height={50} style={{ objectFit: "cover" }} />
                                                    :
                                                    // <img src={defultPlholder} width={50} height={50} style={{ objectFit: "cover" }} />
                                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.00008 8.33333C4.00008 7.87333 4.37341 7.5 4.83342 7.5C5.29342 7.5 5.66675 7.87333 5.66675 8.33333C5.66675 8.79333 5.29342 9.16667 4.83342 9.16667C4.37341 9.16667 4.00008 8.79333 4.00008 8.33333ZM17.3334 8.32083V16.25C17.3334 18.3208 15.6542 20 13.5834 20H4.41675C2.34591 20 0.666748 18.3208 0.666748 16.25V3.75C0.666748 1.67917 2.34591 0 4.41675 0H9.01258C10.4492 0 11.8267 0.570833 12.8426 1.58667L15.7467 4.49083C16.7626 5.50667 17.3334 6.88417 17.3334 8.32083ZM12.2534 2.175C11.7942 1.71583 11.2551 1.36667 10.6667 1.13917V5.41583C10.6667 6.105 11.2276 6.66583 11.9167 6.66583H16.1934C15.9659 6.0775 15.6167 5.53833 15.1576 5.07917L12.2534 2.175ZM1.50008 16.25C1.50008 16.6867 1.59675 17.1017 1.77008 17.4742L6.84675 12.3975C7.80425 11.44 9.36341 11.44 10.3209 12.3975L10.8334 12.91C11.4309 13.5075 12.4034 13.5075 13.0009 12.91L16.5009 9.41V8.32C16.5009 8.04167 16.4759 7.7675 16.4276 7.49917H11.9176C10.7692 7.49917 9.83425 6.56417 9.83425 5.41583V0.906667C9.56592 0.858333 9.29175 0.833333 9.01342 0.833333H4.41758C2.80925 0.833333 1.50091 2.14167 1.50091 3.75L1.50008 16.25ZM16.5001 16.25V10.5892L13.5892 13.5C12.6676 14.4225 11.1659 14.4225 10.2434 13.5L9.73092 12.9875C9.09758 12.3542 8.06841 12.3542 7.43508 12.9875L2.23675 18.1858C2.77175 18.7875 3.55008 19.1667 4.41675 19.1667H13.5834C15.1917 19.1667 16.5001 17.8583 16.5001 16.25Z" fill="#303030" />
                                                    </svg>

                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{interval_unit}</StyledTableCell>
                                            {/* <StyledTableCell align="left">1</StyledTableCell> */}
                                            <StyledTableCell align="left">{row.custom_field_hash.cf_user_type}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                {/* <FormControlLabel
                                                    control={
                                                        <Switch
                                                            // onChange={(e) => togglePlanActive(e, id)} 
                                                            checked={status} />
                                                    }
                                                    label=""
                                                /> */}
                                                <CustomIOSSwitch
                                                    // handleSwitch={(e) => toggleActive(e, id)}
                                                    status={status}
                                                />

                                            </StyledTableCell>
                                            <StyledTableCell align="left">{recurring_price_formatted ? recurring_price_formatted : "Null"}</StyledTableCell>

                                            <StyledTableCell align="left">
                                                {/* {!row.role.role ? */}

                                                <Button
                                                    // startIcon={
                                                    //     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    //         <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" />
                                                    //     </svg>
                                                    // }
                                                    onClick={() => {
                                                        setAssignModal(true)
                                                        setActivePlanId(plan_code)
                                                    }}
                                                    color="primary"
                                                >
                                                    Assign/View Role
                                                </Button>

                                                {/* :
                                                    <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                        sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setAssignModal(true)
                                                            setActivePlanId(plan_code)
                                                        }}
                                                    >
                                                        Role Assigned
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_1839_74338)">
                                                                <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1839_74338">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Stack>
                                                } */}
                                            </StyledTableCell>

                                            {/* <StyledTableCell align="left">{isVerified ? 'Yes' : 'No'}</StyledTableCell>
                        <StyledTableCell align="left">
                          <Label variant="ghost" color={(status === 'banned' && 'error') || 'success'}>
                            {sentenceCase(status)}
                          </Label>
                        </StyledTableCell> */}

                                            {/* <StyledTableCell align="right" style={{ width: '100px' }}>
                                                <UserMoreMenu onEdit={addClient} id={id} />
                                            </StyledTableCell> */}
                                        </StyledTableRow>
                                    );
                                })}
                                {/* {emptyRows > 0 && (
                                                <StyledTableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </StyledTableRow>
                                            )} */}
                            </TableBody>

                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={allPlans.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Scrollbar>

            </Card >
            {/* </Container> */}



            {
                assignModal &&
                <AssignRoleToPlanPopup
                    id={activePlanId}
                    open={assignModal}
                    handleClose={() => handelCloseModal()}
                />
            }

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>
    );
};
